/* eslint-disable no-use-before-define */
/* eslint-disable import/order */
import {
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  InputAdornment,
  FormControlLabel,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef } from "react";
import CardCommon from "../../../card/CardCommon";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import CancelIcon from "@material-ui/icons/Cancel";
import DateRangePicker from "../../DateRangePicker";
import {
  channelSelectorList,
  defaultModeSelectorList,
  partySizeSelectorList,
  payInOutSelectorList,
  paymentTypeSelectorList,
  platformSelectorList,
  typeSelectorList,
  vatPercentSelectorList,
} from "../../../../utils/consts/list";
import { checkArrayObjectIncludeString } from "../../../../utils/commonArrayMap";
import { CustomTheme } from "../../../../types/customTheme";
import SwitchCommon from "../../../switches/SwitchCommon";
import MultipleDateRangePicker from "../../MultipleDateRangePicker";
import MultipleSelectorTextField from "../../selector/MultipleSelectorTextField";
import MultipleSelector from "../../selector/MultipleSelector";
import MultipleSelectorColor from "../../selector/MultipleSelecorColor";
import {
  eatprestoPaymentTypeSelectorList,
  orderStatusSelectorList,
  orderTypeSelectorList,
} from "../../../../utils/consts/listArrays";
import TextfieldCommon from "../../../textField/TextfieldCommon";
import _ from "lodash";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    rangeTextFieldLeftSide: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      [`& fieldset`]: {
        cursor: "pointer",
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    rangeTextFieldRideSide: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
      [`& fieldset`]: {
        cursor: "pointer",
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: `1px solid ${theme.palette.background.entity_border}`,
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
    },
    shiftDateStyle: {
      display: "flex",
      placeItems: "center",
      alignItems: "center",
      justifyContent: "end",
      paddingRight: "12px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "start",
        paddingRight: "0px",
        paddingBottom: "12px",
        paddingLeft: "16px",
      },
    },
    someTextField: {
      minHeight: "34px",
    },
    cancelIconStyle: {
      fontSize: "18px",
      color: "gray",
      cursor: "pointer",
    },
    tabPanel: {
      backgroundColor: theme.palette.background.default,
    },
  }),
);

const useClickOutside = (handler: any) => {
  const domNode: any = useRef();
  useEffect(() => {
    const maybeHandler = (event: any) => {
      if (!domNode?.current?.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

export interface Props {
  setDiscountValueMin: any;
  discountValueMin: any;
  setDiscountValueMax: any;
  discountValueMax: any;
  receiptNo: any;
  locationSelectorList: any;
  handleAllSelectedList: any;
  getLocationSelectorTypingList: any;
  searchLocationName: any;
  setSearchLocationName: any;
  locationSelectedList: any;
  setReceiptNo: any;
  setQueOrderNo: any;
  queOrderNo: any;
  handleChangeDateRange: any;
  dateSelectionRange: any;
  startDate: any;
  endDate: any;
  totalAmountMin: any;
  totalAmountMax: any;
  setTotalAmountMin: any;
  setTotalAmountMax: any;
  orderNo: any;
  setOrderNo: any;
  typeSelectedList: any;
  channelSelectedList: any;
  partySizeSelectedList: any;
  paymentTypeSelectedList: any;
  userSelectedList: any;
  userSelectorList: any;
  setFilterOrderNo: any;
  setFilterQueOrderNo: any;
  setFilterReceiptNo: any;
  platformSelectedList: any;
  setFilterTotalAmountMin: any;
  setFilterTotalAmountMax: any;
  setFilterDiscountValueMin: any;
  setFilterDiscountValueMax: any;
  isBasedOnShift: any;
  handleBasedOnShift: any;
  availableFilter: any;
  qtyMin: any;
  setQtyMin: any;
  qtyMax: any;
  setQtyMax: any;
  setFilterQtyMin: any;
  setFilterQtyMax: any;
  amountMin: any;
  amountMax: any;
  setAmountMin: any;
  setAmountMax: any;
  setFilterAmountMin: any;
  setFilterAmountMax: any;
  vatPercentSelectedList: any;
  handleChangePreviousDateRange?: any;
  previousDateSelectionRange?: any;
  previousStartDate?: any;
  previousEndDate?: any;
  isChart: any;
  payInOutSelectedList: any;
  allTypeSelectedList: any;
  isBasedOnShiftButtonDisabled: any;
  orderStatusSelectedList: any;
  orderTotalMin: any;
  setOrderTotalMin: any;
  orderTotalMax: any;
  setOrderTotalMax: any;
  setFilterOrderTotalMax: any;
  setFilterOrderTotalMin: any;
  isOrder: any;
  deliveryTpeSelectedList: any;
  handleChangeOrderAndDueTimeSwitch: any;
  orderAndDueTimeSwitch: any;
  isOrderReport: any;
}

const FilterCard: React.FunctionComponent<Props> = ({
  setDiscountValueMin,
  discountValueMin,
  totalAmountMin,
  totalAmountMax,
  setDiscountValueMax,
  setReceiptNo,
  discountValueMax,
  receiptNo,
  locationSelectorList,
  handleAllSelectedList,
  getLocationSelectorTypingList,
  searchLocationName,
  setSearchLocationName,
  locationSelectedList,
  setQueOrderNo,
  queOrderNo,
  handleChangeDateRange,
  dateSelectionRange,
  startDate,
  endDate,
  setTotalAmountMin,
  setTotalAmountMax,
  orderNo,
  setOrderNo,
  typeSelectedList,
  channelSelectedList,
  partySizeSelectedList,
  paymentTypeSelectedList,
  userSelectedList,
  userSelectorList,
  setFilterOrderNo,
  setFilterQueOrderNo,
  setFilterReceiptNo,
  platformSelectedList,
  setFilterTotalAmountMin,
  setFilterTotalAmountMax,
  setFilterDiscountValueMin,
  setFilterDiscountValueMax,
  isBasedOnShift,
  handleBasedOnShift,
  availableFilter,
  qtyMin,
  setQtyMin,
  qtyMax,
  setQtyMax,
  setFilterQtyMin,
  setFilterQtyMax,
  amountMin,
  amountMax,
  setAmountMin,
  setAmountMax,
  setFilterAmountMin,
  setFilterAmountMax,
  vatPercentSelectedList,
  handleChangePreviousDateRange,
  previousDateSelectionRange,
  previousStartDate,
  previousEndDate,
  isChart,
  payInOutSelectedList,
  allTypeSelectedList,
  isBasedOnShiftButtonDisabled,
  orderStatusSelectedList,
  orderTotalMin,
  setOrderTotalMin,
  orderTotalMax,
  setOrderTotalMax,
  setFilterOrderTotalMax,
  setFilterOrderTotalMin,
  isOrder,
  deliveryTpeSelectedList,
  handleChangeOrderAndDueTimeSwitch,
  orderAndDueTimeSwitch,
  isOrderReport,
}) => {
  const [open, setOpen] = React.useState(false);

  /* After clicking outside the date range selector is closed. */
  const domNode = useClickOutside(() => {
    setOpen(false);
  });

  /* After pressing the enter key, changes the state. */
  const handleKeyDownEvent = (e: any) => {
    if (e.key === "Enter") {
      switch (e.target.name) {
        case "orderNo":
          setFilterOrderNo(e.target.value);
          break;
        case "queOrderNo":
          setFilterQueOrderNo(e.target.value);
          break;
        case "receiptNo":
          setFilterReceiptNo(e.target.value);
          break;
        case "totalAmountMin":
          setFilterTotalAmountMin(e.target.value);
          if (parseFloat(e.target.value) > parseFloat(totalAmountMax)) {
            setFilterTotalAmountMax("");
            setTotalAmountMax("");
          }
          break;
        case "totalAmountMax":
          setFilterTotalAmountMax(e.target.value);
          if (parseFloat(e.target.value) < parseFloat(totalAmountMin)) {
            setFilterTotalAmountMin("");
            setTotalAmountMin("");
          }
          break;
        case "discountValueMin":
          setFilterDiscountValueMin(e.target.value);
          if (parseFloat(e.target.value) > parseFloat(discountValueMax)) {
            setFilterDiscountValueMax("");
            setDiscountValueMax("");
          }
          break;
        case "qtyMin":
          setFilterQtyMin(e.target.value);
          if (parseFloat(e.target.value) > parseFloat(qtyMax)) {
            setFilterQtyMax("");
            setQtyMax("");
          }
          break;
        case "discountValueMax":
          setFilterDiscountValueMax(e.target.value);
          if (parseFloat(e.target.value) < parseFloat(discountValueMin)) {
            setFilterDiscountValueMin("");
            setDiscountValueMin("");
          }
          break;
        case "qtyMax":
          setFilterQtyMax(e.target.value);
          if (parseFloat(e.target.value) < parseFloat(qtyMin)) {
            setFilterQtyMin("");
            setQtyMin("");
          }
          break;
        case "amountMin":
          setFilterAmountMin(e.target.value);
          if (parseFloat(e.target.value) > parseFloat(amountMax)) {
            setFilterAmountMax("");
            setAmountMax("");
          }
          break;
        case "amountMax":
          setFilterAmountMax(e.target.value);
          if (parseFloat(e.target.value) < parseFloat(amountMin)) {
            setFilterAmountMin("");
            setAmountMin("");
          }
          break;
        case "orderTotalMin":
          setFilterOrderTotalMin(e.target.value);
          if (parseFloat(e.target.value) > parseFloat(orderTotalMax)) {
            setFilterOrderTotalMax("");
            setOrderTotalMax("");
          }
          break;
        case "orderTotalMax":
          setFilterOrderTotalMax(e.target.value);
          if (parseFloat(e.target.value) < parseFloat(orderTotalMin)) {
            setFilterOrderTotalMin("");
            setOrderTotalMin("");
          }
          break;
        default:
          break;
      }
    }
  };

  const handleClearQty = () => {
    setQtyMin("");
    setFilterQtyMin("");
    setQtyMax("");
    setFilterQtyMax("");
  };

  const handleClearDiscount = () => {
    setDiscountValueMin("");
    setFilterDiscountValueMin("");
    setDiscountValueMax("");
    setFilterDiscountValueMax("");
  };

  const handleClearTotalAmount = () => {
    setTotalAmountMin("");
    setFilterTotalAmountMin("");
    setTotalAmountMax("");
    setFilterTotalAmountMax("");
  };

  const handleClearAmount = () => {
    setAmountMin("");
    setFilterAmountMin("");
    setAmountMax("");
    setFilterAmountMax("");
  };

  const handleClearOrderTotal = () => {
    setOrderTotalMin("");
    setOrderTotalMax("");
    setFilterOrderTotalMax("");
    setFilterOrderTotalMin("");
  };

  const horizontalMaxWidth = useMediaQuery("(max-width: 960px)");
  const classes = useStyles();

  return (
    <div style={{ marginBottom: "32px" }}>
      <CardCommon backgroundColor={"entity_background"}>
        <div style={{ margin: "12px" }}>
          <CardCommon backgroundColor={"entity_highlight_background"}>
            <Grid container>
              {availableFilter.LocationSelector.available && (
                <Grid
                  item
                  xs={12}
                  md={
                    availableFilter.basedOnShift.available ||
                    availableFilter.dueTimeOrderTimeSwitch.available
                      ? 8
                      : 12
                  }
                  lg={
                    availableFilter.basedOnShift.available ||
                    availableFilter.dueTimeOrderTimeSwitch.available
                      ? 9
                      : 12
                  }
                  style={{ padding: "6px 0px 14px 2px" }}
                >
                  <MultipleSelectorTextField
                    selectorList={locationSelectorList}
                    handleSelectedList={handleAllSelectedList}
                    getLocationSelectorTypingList={
                      getLocationSelectorTypingList
                    }
                    searchLocationName={searchLocationName}
                    setSearchLocationName={setSearchLocationName}
                    selectedList={locationSelectedList}
                  />
                </Grid>
              )}
              {availableFilter.basedOnShift.available && (
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  style={{ width: "100%" }}
                  className={classes.shiftDateStyle}
                >
                  {" "}
                  <CardCommon
                    backgroundColor={"entity_background"}
                    style={{ width: "100%" }}
                  >
                    <FormControlLabel
                      control={<SwitchCommon />}
                      checked={isBasedOnShift}
                      onChange={handleBasedOnShift}
                      style={{
                        marginRight: "0px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      disabled={isBasedOnShiftButtonDisabled}
                      label={
                        <Typography
                          align="left"
                          style={{ fontWeight: "bold", fontSize: "14px" }}
                        >
                          Report based on shift’s dates
                        </Typography>
                      }
                      labelPlacement="start"
                    />{" "}
                  </CardCommon>
                </Grid>
              )}

              {availableFilter.dueTimeOrderTimeSwitch.available && (
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  className={classes.shiftDateStyle}
                >
                  {" "}
                  <CardCommon backgroundColor={"entity_background"}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        variant="body2"
                        style={{
                          paddingTop: "9px",
                          fontWeight: "bold",
                          marginLeft: "12px",
                        }}
                      >
                        Due Time
                      </Typography>
                      <FormControlLabel
                        control={<SwitchCommon />}
                        checked={orderAndDueTimeSwitch}
                        onChange={handleChangeOrderAndDueTimeSwitch}
                        style={{ marginRight: "12px", marginLeft: "4px" }}
                        disabled={isBasedOnShiftButtonDisabled}
                        label={
                          <Typography
                            align="center"
                            style={{ fontWeight: "bold" }}
                            variant="body2"
                          >
                            Order Time
                          </Typography>
                        }
                      />{" "}
                    </div>
                  </CardCommon>
                </Grid>
              )}
            </Grid>
          </CardCommon>
        </div>
        <Grid
          container
          spacing={2}
          style={{
            marginBottom: "10px",
          }}
        >
          {availableFilter.dateRange.available && (
            <Grid
              ref={domNode}
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                marginTop: "12px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                Date range
              </Typography>
              {isChart ? (
                <MultipleDateRangePicker
                  open={open}
                  setOpen={setOpen}
                  startDate={startDate}
                  endDate={endDate}
                  handleChangeDateRange={handleChangeDateRange}
                  dateSelectionRange={dateSelectionRange}
                  horizontalMaxWidth={horizontalMaxWidth}
                  handleChangePreviousDateRange={handleChangePreviousDateRange}
                  previousDateSelectionRange={previousDateSelectionRange}
                  previousStartDate={previousStartDate}
                  previousEndDate={previousEndDate}
                />
              ) : (
                <DateRangePicker
                  open={open}
                  setOpen={setOpen}
                  startDate={startDate}
                  endDate={endDate}
                  handleChangeDateRange={handleChangeDateRange}
                  dateSelectionRange={dateSelectionRange}
                  horizontalMaxWidth={horizontalMaxWidth}
                />
              )}
            </Grid>
          )}
          {availableFilter.mode.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                Mode
              </Typography>
              <MultipleSelector
                selectorTextFieldName={"mode"}
                // disabled={locationSelectedList.length > 1}
                selectorList={defaultModeSelectorList}
                handleAllSelectedList={handleAllSelectedList}
                selectedList={typeSelectedList}
              />
            </Grid>
          )}
          {availableFilter.platform.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                Platform
              </Typography>
              <MultipleSelector
                selectorTextFieldName={"platform"}
                selectorList={platformSelectorList}
                handleAllSelectedList={handleAllSelectedList}
                selectedList={platformSelectedList}
              />
            </Grid>
          )}
          {availableFilter.channel.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                Channel
              </Typography>
              <MultipleSelector
                selectorTextFieldName={"channel"}
                disabled={
                  (_.isEmpty(platformSelectedList)
                    ? false
                    : !checkArrayObjectIncludeString(
                        platformSelectedList,
                        "online",
                      )) && !isOrder
                }
                selectorList={channelSelectorList}
                handleAllSelectedList={handleAllSelectedList}
                selectedList={channelSelectedList}
              />
            </Grid>
          )}
          {availableFilter.orderNo.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                Order No
              </Typography>
              <TextfieldCommon
                id="orderNo"
                name="orderNo"
                inputProps={{ classes: { input: classes.someTextField } }}
                value={orderNo}
                type={"text"}
                onChange={(e: any) => setOrderNo(e.target.value)}
                onKeyDown={(e: any) => handleKeyDownEvent(e)}
              />
            </Grid>
          )}
          {availableFilter.orderTotal.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  placeItems: "center",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", paddingRight: "12px" }}
                  variant="body2"
                >
                  Order Total
                </Typography>
                <CancelIcon
                  className={classes.cancelIconStyle}
                  onClick={handleClearOrderTotal}
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  id="orderTotalMin"
                  name="orderTotalMin"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">From</InputAdornment>
                    ),
                    classes: { input: classes.someTextField },
                  }}
                  // style={{ paddingLeft: "12px" }}
                  className={classes.rangeTextFieldLeftSide}
                  value={orderTotalMin}
                  variant="outlined"
                  fullWidth
                  type={"number"}
                  onChange={(e: any) => setOrderTotalMin(e.target.value)}
                  onKeyDown={(e: any) => handleKeyDownEvent(e)}
                />
                <TextField
                  id="orderTotalMax"
                  name="orderTotalMax"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">To</InputAdornment>
                    ),
                    classes: { input: classes.someTextField },
                  }}
                  className={classes.rangeTextFieldRideSide}
                  value={orderTotalMax}
                  variant="outlined"
                  type={"number"}
                  fullWidth
                  size="small"
                  onChange={(e: any) => setOrderTotalMax(e.target.value)}
                  onKeyDown={(e: any) => handleKeyDownEvent(e)}
                />
              </div>
            </Grid>
          )}

          {availableFilter.displayId.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                Display Ref
              </Typography>
              <TextfieldCommon
                id="queOrderNo"
                name="queOrderNo"
                inputProps={{ classes: { input: classes.someTextField } }}
                value={queOrderNo}
                type={"text"}
                onChange={(e: any) => setQueOrderNo(e.target.value)}
                onKeyDown={(e: any) => handleKeyDownEvent(e)}
              />
            </Grid>
          )}
          {availableFilter.receiptNo.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                Receipt No
              </Typography>
              <TextfieldCommon
                id="receiptNo"
                name="receiptNo"
                inputProps={{ classes: { input: classes.someTextField } }}
                value={receiptNo}
                type={"text"}
                onChange={(e: any) => setReceiptNo(e.target.value)}
                onKeyDown={(e: any) => handleKeyDownEvent(e)}
              />
            </Grid>
          )}
          {availableFilter.payInOut.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                Pay In Out
              </Typography>
              <MultipleSelectorColor
                selectorTextFieldName={"payInOut"}
                disabled={false}
                selectorList={payInOutSelectorList}
                handleAllSelectedList={handleAllSelectedList}
                selectedList={payInOutSelectedList}
              />
            </Grid>
          )}
          {availableFilter.user.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                User
              </Typography>
              <MultipleSelector
                selectorTextFieldName={"user"}
                disabled={locationSelectedList.length > 1}
                selectorList={userSelectorList}
                handleAllSelectedList={handleAllSelectedList}
                selectedList={userSelectedList}
              />
            </Grid>
          )}
          {availableFilter.paymentType.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                Payment Type
              </Typography>
              <MultipleSelector
                selectorTextFieldName={"paymentType"}
                selectorList={
                  isOrder || isOrderReport
                    ? eatprestoPaymentTypeSelectorList
                    : paymentTypeSelectorList
                }
                handleAllSelectedList={handleAllSelectedList}
                selectedList={paymentTypeSelectedList}
                isOrderReport={isOrderReport}
              />
            </Grid>
          )}
          {availableFilter.orderStatus.available && (
            <Grid item md={3} xs={12} sm={6}>
              <Typography
                variant="body2"
                style={{
                  paddingTop: "10px",
                  fontWeight: "bold",
                  paddingBottom: "8px",
                }}
              >
                Order Status
              </Typography>
              {/* Uses this component select item */}
              <MultipleSelector
                selectorTextFieldName={"orderStatus"}
                selectorList={orderStatusSelectorList}
                handleAllSelectedList={handleAllSelectedList}
                selectedList={orderStatusSelectedList}
              />
            </Grid>
          )}
          {availableFilter.orderType.available && (
            <Grid item md={3} xs={12} sm={6}>
              <Typography
                variant="body2"
                style={{
                  paddingTop: "10px",
                  fontWeight: "bold",
                  paddingBottom: "8px",
                }}
              >
                Order Type
              </Typography>
              {/* Uses this component select item */}
              <MultipleSelector
                selectorTextFieldName={"deliveryType"}
                selectorList={orderTypeSelectorList}
                handleAllSelectedList={handleAllSelectedList}
                selectedList={deliveryTpeSelectedList}
              />
            </Grid>
          )}
          {availableFilter.totalAmount.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  placeItems: "center",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", paddingRight: "12px" }}
                  variant="body2"
                >
                  Total Amount
                </Typography>
                <CancelIcon
                  className={classes.cancelIconStyle}
                  onClick={handleClearTotalAmount}
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  id="totalAmountMin"
                  name="totalAmountMin"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">From</InputAdornment>
                    ),
                    classes: { input: classes.someTextField },
                  }}
                  size="small"
                  style={{
                    paddingLeft: "12px",
                  }}
                  className={classes.rangeTextFieldLeftSide}
                  value={totalAmountMin}
                  variant="outlined"
                  type={"number"}
                  fullWidth
                  onChange={(e: any) => setTotalAmountMin(e.target.value)}
                  onKeyDown={(e: any) => handleKeyDownEvent(e)}
                />
                <TextField
                  id="totalAmountMax"
                  name="totalAmountMax"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">To</InputAdornment>
                    ),
                    classes: { input: classes.someTextField },
                  }}
                  size="small"
                  className={classes.rangeTextFieldRideSide}
                  style={{
                    paddingRight: "12px",
                  }}
                  value={totalAmountMax}
                  variant="outlined"
                  fullWidth
                  type={"number"}
                  onChange={(e: any) => setTotalAmountMax(e.target.value)}
                  onKeyDown={(e: any) => handleKeyDownEvent(e)}
                />
              </div>
            </Grid>
          )}
          {availableFilter.amount.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  placeItems: "center",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", paddingRight: "12px" }}
                  variant="body2"
                >
                  Amount
                </Typography>
                <CancelIcon
                  className={classes.cancelIconStyle}
                  onClick={handleClearAmount}
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  id="amountMin"
                  name="amountMin"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">From</InputAdornment>
                    ),
                    classes: { input: classes.someTextField },
                  }}
                  // style={{ paddingLeft: "12px" }}
                  size="small"
                  className={classes.rangeTextFieldLeftSide}
                  value={amountMin}
                  variant="outlined"
                  fullWidth
                  type={"number"}
                  onChange={(e: any) => setAmountMin(e.target.value)}
                  onKeyDown={(e: any) => handleKeyDownEvent(e)}
                />
                <TextField
                  id="amountMax"
                  name="amountMax"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">To</InputAdornment>
                    ),
                    classes: { input: classes.someTextField },
                  }}
                  size="small"
                  className={classes.rangeTextFieldRideSide}
                  value={amountMax}
                  variant="outlined"
                  type={"number"}
                  fullWidth
                  onChange={(e: any) => setAmountMax(e.target.value)}
                  onKeyDown={(e: any) => handleKeyDownEvent(e)}
                />
              </div>
            </Grid>
          )}
          {availableFilter.qty.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  placeItems: "center",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", paddingRight: "12px" }}
                  variant="body2"
                >
                  Quantity
                </Typography>
                <CancelIcon
                  className={classes.cancelIconStyle}
                  onClick={handleClearQty}
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  id="qtyMin"
                  name="qtyMin"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">From</InputAdornment>
                    ),
                    classes: { input: classes.someTextField },
                  }}
                  // style={{ paddingLeft: "12px" }}
                  size="small"
                  className={classes.rangeTextFieldLeftSide}
                  value={qtyMin}
                  variant="outlined"
                  fullWidth
                  type={"number"}
                  onChange={(e: any) => setQtyMin(e.target.value)}
                  onKeyDown={(e: any) => handleKeyDownEvent(e)}
                />
                <TextField
                  id="qtyMax"
                  name="qtyMax"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">To</InputAdornment>
                    ),
                    classes: { input: classes.someTextField },
                  }}
                  size="small"
                  className={classes.rangeTextFieldRideSide}
                  value={qtyMax}
                  variant="outlined"
                  type={"number"}
                  fullWidth
                  onChange={(e: any) => setQtyMax(e.target.value)}
                  onKeyDown={(e: any) => handleKeyDownEvent(e)}
                />
              </div>
            </Grid>
          )}
          {availableFilter.discount.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  placeItems: "center",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", paddingRight: "12px" }}
                  variant="body2"
                >
                  Discount
                </Typography>
                <CancelIcon
                  className={classes.cancelIconStyle}
                  onClick={handleClearDiscount}
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  id="discountValueMin"
                  name="discountValueMin"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">From</InputAdornment>
                    ),
                    classes: { input: classes.someTextField },
                  }}
                  size="small"
                  style={{ paddingLeft: "12px" }}
                  className={classes.rangeTextFieldLeftSide}
                  value={discountValueMin}
                  variant="outlined"
                  fullWidth
                  type={"number"}
                  onChange={(e: any) => setDiscountValueMin(e.target.value)}
                  onKeyDown={(e: any) => handleKeyDownEvent(e)}
                />
                <TextField
                  id="discountValueMax"
                  name="discountValueMax"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">To</InputAdornment>
                    ),
                    classes: { input: classes.someTextField },
                  }}
                  size="small"
                  className={classes.rangeTextFieldRideSide}
                  value={discountValueMax}
                  variant="outlined"
                  type={"number"}
                  fullWidth
                  onChange={(e: any) => setDiscountValueMax(e.target.value)}
                  onKeyDown={(e: any) => handleKeyDownEvent(e)}
                />
              </div>
            </Grid>
          )}
          {availableFilter.partySize.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                Party Size
              </Typography>
              <MultipleSelector
                selectorTextFieldName={"partySize"}
                selectorList={partySizeSelectorList}
                handleAllSelectedList={handleAllSelectedList}
                selectedList={partySizeSelectedList}
              />
            </Grid>
          )}

          {availableFilter.vatPercent.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                Vat Percent
              </Typography>
              <MultipleSelector
                selectorTextFieldName={"vatPercent"}
                selectorList={vatPercentSelectorList}
                handleAllSelectedList={handleAllSelectedList}
                selectedList={vatPercentSelectedList}
              />
            </Grid>
          )}
          {availableFilter.type.available && (
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              style={{
                marginTop: "12px",
              }}
            >
              <Typography style={{ fontWeight: "bold" }} variant="body2">
                Type
              </Typography>
              <MultipleSelectorColor
                selectorTextFieldName={"type"}
                disabled={false}
                selectorList={typeSelectorList}
                handleAllSelectedList={handleAllSelectedList}
                selectedList={allTypeSelectedList}
              />
            </Grid>
          )}
        </Grid>
      </CardCommon>
    </div>
  );
};

export default FilterCard;
