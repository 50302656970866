import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { TextField } from "@material-ui/core";
import { CustomTheme } from "../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      border: "none",
      backgroundColor: theme.palette.background.entity_dialog_textfield_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface TextfieldCommonProps {
  id: any;
  name: any;
  label?: any;
  disabled?: any;
  type: any;
  inputRef?: any;
  helperText?: any;
  style?: any;
  value?: any;
  onChange?: any;
  inputProps?: any;
  error?: any;
  onKeyDown?: any;
  margin?: any;
  defaultValue?: any;
  multiline?: any;
  rows?: any;
  autoComplete?: any;
}

/* Common textfield */
const TextfieldDefaultNew: React.FunctionComponent<TextfieldCommonProps> = ({
  id,
  name,
  label,
  disabled,
  inputRef,
  helperText,
  type,
  style,
  value,
  onChange,
  inputProps,
  error,
  onKeyDown,
  margin = "dense",
  defaultValue,
  multiline,
  rows,
  autoComplete,
}) => {
  const classes = useStyles();

  return (
    <TextField
      id={id}
      name={name}
      label={label}
      value={value}
      InputProps={inputProps}
      className={classes.textField}
      onChange={onChange}
      onKeyDown={onKeyDown}
      style={style}
      variant="outlined"
      error={error}
      disabled={disabled}
      type={type}
      fullWidth
      margin={margin}
      defaultValue={defaultValue}
      inputRef={inputRef}
      helperText={helperText}
      multiline={multiline}
      rows={rows}
      autoComplete={autoComplete}
    />
  );
};

export default TextfieldDefaultNew;
