import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import PaginationUsingSelector from "./PaginationUsingSelector";
import PaginationUsingPageNumber from "./PaginationUsingPageNumber";

export interface PaginationProps {
  handleChangePaginationUsingPageNumber: any;
  handleChangePaginationUsingSelector: any;
  totalPages: any;
  currentPage: any;
  pageSize: any;
  totalResult: any;
  isShift?: any;
  isCustomer?: any;
}

/* Show the page size selection dropdown and page number selection button. */
const Pagination: React.FunctionComponent<PaginationProps> = ({
  handleChangePaginationUsingPageNumber,
  totalPages,
  currentPage,
  handleChangePaginationUsingSelector,
  pageSize,
  totalResult,
  isShift,
  isCustomer,
}) => {
  const normalPageSize = {
    10: "0-10",
    25: "0-10",
  };

  return (
    <Grid container style={{ marginTop: "20px" }}>
      <Grid item sm={6} xs={4}>
        <PaginationUsingSelector
          handleChangePaginationUsingSelector={
            handleChangePaginationUsingSelector
          }
          pageSize={pageSize}
          isShift={isShift}
          isCustomer={isCustomer}
        />
      </Grid>
      <Grid item sm={6} xs={8}  style={{display: "flex", justifyContent: "end"}}>
        <div>
        <PaginationUsingPageNumber
          handleChangePaginationUsingPageNumber={
            handleChangePaginationUsingPageNumber
          }
          totalPages={totalPages}
          currentPage={currentPage}
        />
        <div style={{marginTop: "4px"}}>
          <Typography>
            {`${pageSize * currentPage - (pageSize - 1)} - ${
              pageSize * currentPage
            } out of ${totalResult} for page ${currentPage}`}
          </Typography>
        </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Pagination;
