import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Box } from "@material-ui/core";
import _ from "lodash";

import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import PaymentReportInfoNode from "./PaymentReportInfoNode";
import { fetchAllPaymentReportInfo } from "../../../../services/salesApp/paymentReportService";
import { paymentFilterObject } from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";

/**
 * PaymentReport Component
 *
 * The PaymentReport component is responsible for displaying the payment summary report
 * for a specific location within the PrestoExpress application. It utilizes Material-UI
 * components and various utility functions to fetch and display payment-related information.
 */
const PaymentReport: React.FunctionComponent<{}> = () => {
  const [paymentReportNodeList, setPaymentReportNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [filterDetails, setFilterDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const [basedOnShift, setBasedOnShift] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /**
   * Asynchronous function to fetch payment report information based on filter criteria.
   * It updates the component state with the fetched data or handles errors appropriately.
   */
  const getPaymentReportInfo = async (filter: any, basedOnShift: boolean) => {
    // Clear the existing payment report node list
    setPaymentReportNodeList([]);
    try {
      // Fetch payment report information from the server
      const response = await fetchAllPaymentReportInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      // Check if the fetched data is not empty
      const hasData = Object.values(response.data.data).some(
        (data) => !_.isEmpty(data),
      );

      // Update the state with the fetched data or an empty array if no data is available
      if (hasData) {
        setPaymentReportNodeList(response.data.data);
      } else {
        setPaymentReportNodeList([]);
      }

      // Set loading state to false
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Fetches and updates the location selector list based on the provided search name.
   *
   * @param {string} searchName - The search name used for filtering locations.
   */
  const getAllFilterLocation = async (searchName: any) => {
    try {
      // Fetch all filter locations using the provided search name
      const response = await fetchAllFilterLocations(searchName);

      // Process the fetched data to create a list of
      if (!_.isEmpty(response.data.data)) {
        const locationList = response.data.data.map((location: any) => ({
          id: location.id,
          label: location.businessDisplayName,
        }));

        // Update the location selector list and set the flag indicating location information is retrieved
        setLocationSelectorList(locationList);
      } else {
        setLocationSelectorList([]);
      }

      setIsGetLocationInfo(true);
    } catch (error) {
      // Handle errors by setting an error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * useEffect hook to set the document title and fetch initial location filter data.
   * It runs only once when the component mounts.
   */
  useEffect(() => {
    // Set the document title for the page
    document.title = "Sale - Payment Report";

    // Fetch initial location filter data with an empty searchName
    getAllFilterLocation("");
  }, []);

  /**
   * Handles the filter data and triggers the update of the payment report.
   */
  const handleFilterData = (filterData: any, basedOnShift: boolean) => {
    setIsLoading(true);

    // Fetch and update the payment report information based on the provided filter data and shift flag
    getPaymentReportInfo(filterData, basedOnShift);

    // Set the filter data and shift flag
    setFilterData(filterData);
    setBasedOnShift(basedOnShift);
  };

  /**
   * Handles the click event on the text, opens the filter card, and scrolls to the top.
   */
  const handleOnClickText = () => {
    setOpenFilterCard(true);

    // Scroll to the top of the window with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={paymentFilterObject}
          isOpenSkeletonLoading={isLoading}
          nodeList={paymentReportNodeList}
          filterDetails={filterDetails}
          title={""}
          isChart={false}
          filterTitle="Payment summary report for"
          topic="Payment Summary"
          isNeedFilterDetails={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          isReport={true}
          payInOut={true}
          isInitialLoad={isInitialLoad}
          setIsInitialLoad={setIsInitialLoad}
          initialParamSize={5}
        >
          <PaymentReportInfoNode
            paymentReportNodeList={paymentReportNodeList}
            locationSelectorList={locationSelectorList}
            filterDetails={filterDetails}
            filterData={filterData}
            basedOnShift={basedOnShift}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
          />
        </SaleReport>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(PaymentReport, Authorities.SALE_READ);
