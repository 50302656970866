import React, { useContext } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { initTheme } from "./theme/primary";
import PrimaryLayout from "../../layouts/PrimaryLayout";
import { useRouteMatch } from "react-router-dom";
import { GlobalContext } from "../../root/App";
import { CssBaseline } from "@material-ui/core";
import SaleAppRoutes, { getSidebarRoutes } from "./routes/SaleAppRoutes";
import { getCookie } from "../../utils/cookies";
import { fetchAllLocations } from "../../services/locationApp/locationService";
import { getFilterListFromArrayObject } from "../../utils/commonArrayMap";
import { handleChangeRestaurant } from "../../utils";
import { SALE_QUERY_PARAMS } from "../../utils/consts";
import queryString from "query-string";

export interface PrestoExpressLocationAppProps {}

const PrestoExpressLocationApp: React.FunctionComponent<
  PrestoExpressLocationAppProps
> = () => {
  const [sidebarRoutes, setSidebarRoutes] = React.useState<any>([]);
  const { globalState } = useContext(GlobalContext);
  const theme = initTheme({ isDark: globalState.isDarkMode });
  const match: any = useRouteMatch();

  const getLocationInfo = async () => {
    try {
      const idToken: any = getCookie("idToken");
      const res = await fetchAllLocations(idToken);
      const location = getFilterListFromArrayObject(
        res.data.data,
        match.params.locationId,
      );
      handleChangeRestaurant(location[0]);
      handleChangeSidebar();
    } catch (error) {}
  };

  const handleChangeSidebar = () => {
    const sidebar = getSidebarRoutes();
    setSidebarRoutes(sidebar);
  };

  React.useEffect(() => {
    getLocationInfo();
  }, []);

  /**
   * Extracts the 'app' parameter from the query string in the window's location.
   * Uses the queryString library to parse the query string and retrieve the 'app' value.
   *
   * @constant {string} selectedApp - The value of the 'app' parameter from the query string.
   */
  const selectedApp = queryString.parse(window.location.search).app;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PrimaryLayout
        sidebarRoutes={sidebarRoutes}
        handleChangeSidebar={handleChangeSidebar}
      >
        {(selectedApp === SALE_QUERY_PARAMS || sidebarRoutes.length > 0) && (
          <SaleAppRoutes />
        )}
      </PrimaryLayout>
    </ThemeProvider>
  );
};

export default PrestoExpressLocationApp;
