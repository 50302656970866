import { axiosClient } from "../client";

const queryString = require("query-string");

const CUSTOMER_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CUSTOMER}/location/${locationId}/customers/list`;

export const fetchAllCustomerInfo = (
  token,
  locationId,
  size,
  currentPage,
  shortingMethod,
  shortingHeaderName,
  filterData,
) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl(
      {
        url: CUSTOMER_INFO_GET_ALL(locationId),
        query: {
          size: size,
          page: currentPage,
          sort: [shortingHeaderName, shortingMethod],
          where: filterData,
        },
      },
      {
        sort: false,
        arrayFormat: "comma",
        skipNull: true,
        skipEmptyString: true,
      },
    ),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
