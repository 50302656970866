import { Badge, IconButton, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import _ from "lodash";
import TuneIcon from "@material-ui/icons/Tune";

import DefaultAlert from "../../alerts/DefaultAlert";
import { CustomTheme } from "../../../types/customTheme";
import FilterCard from "./FilterCard/FilterCard";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    iconButton: {
      "&:hover": {
        background: "none",
        backgroundColor: theme.palette.background.paper,
        border: `2px solid ${theme.palette.background.entity_border}`,
      },
      backgroundColor: theme.palette.background.entity_background,
      marginBottom: "8px",
      display: "flex",
      alignItems: "center",
      borderRadius: "10px",
      border: `2px solid ${theme.palette.background.entity_border}`,
      textAlign: "center",
      width: "120px",
      height: "44px",
    },
    badgeFontColor: {
      "& .MuiBadge-badge": {
        color: "white",
      },
    },
  }),
);

export interface Props {
  setOpenFilterCard: any;
  openFilterCard: any;
  activeInactiveSelectedList: any;
  setActiveInactiveSelectedList: any;
}

/**
 * This component represents a custom filter control for managing filters in the UI.
 * It includes an icon button with a badge indicating the number of active filters,
 * and when clicked, it opens a filter card for selecting filter options. The component
 * is designed to be reusable and adaptable for different filter scenarios.
 */
const FilterCustom: React.FunctionComponent<Props> = ({
  setOpenFilterCard,
  openFilterCard,
  activeInactiveSelectedList,
  setActiveInactiveSelectedList,
}) => {
  const [error, setError] = useState("");

  /* Open and close filter card */
  const handleChangeFilterCard = () => {
    setOpenFilterCard(!openFilterCard);
  };

  const handleAllSelectedList = (selectorName: string, selectedList: any) => {
    // Switch statement based on the dropdown selector name.
    switch (selectorName) {
      case "status":
        setActiveInactiveSelectedList(selectedList);
        break;
      default:
        break;
    }
  };

  const classes = useStyles();

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "start", marginTop: "16px" }}
      >
        <Badge
          badgeContent={activeInactiveSelectedList.length > 0 ? 1 : 0}
          color="secondary"
          className={classes.badgeFontColor}
        >
          <IconButton
            className={classes.iconButton}
            onClick={handleChangeFilterCard}
          >
            <Typography style={{ display: "flex", alignItems: "center" }}>
              <TuneIcon fontSize="small" />
            </Typography>
            <Typography style={{ marginLeft: "8px", letterSpacing: "1px" }}>
              Filters
            </Typography>
          </IconButton>
        </Badge>
      </div>
      {openFilterCard && (
        <FilterCard
          handleAllSelectedList={handleAllSelectedList}
          activeInactiveSelectedList={activeInactiveSelectedList}
        />
      )}
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default FilterCustom;
