import firebase from "firebase/app";
import moment from "moment";
import { getCookie } from "../cookies";
import { fetchIpAddress } from "../../services/getIpAddress";

// Initialize Firebase (you need to replace the values with your Firebase config)
const firebaseConfigLogs = {
  apiKey: "AIzaSyAytnxAZCwIdm0cskmG0hUtGhvLnnBgxDU",
  authDomain: "presto-express-logs-dev.firebaseapp.com",
  databaseURL:
    "https://presto-express-logs-dev-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "presto-express-logs-dev",
  storageBucket: "presto-express-logs-dev.appspot.com",
  messagingSenderId: "610427648915",
  appId: "1:610427648915:web:ba7ca643f9e0b9ce8e0666",
  measurementId: "G-3T5PM93ZT0",
};

export const saveLogs = async (data: any, app: any, locationId: any) => {
  const userAgent = navigator.userAgent;

  const isChrome = /Chrome/.test(userAgent) && !/Edge/.test(userAgent);
  const isFirefox = /Firefox/.test(userAgent);
  const isEdge = /Edge/.test(userAgent);
  const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
  const isOpera = /Opera/.test(userAgent) || /OPR/.test(userAgent);

  let browserName = "Unknown Browser";
  if (isChrome) {
    browserName = "Google Chrome";
  } else if (isFirefox) {
    browserName = "Mozilla Firefox";
  } else if (isEdge) {
    browserName = "Microsoft Edge";
  } else if (isSafari) {
    browserName = "Apple Safari";
  } else if (isOpera) {
    browserName = "Opera";
  }

  const firebaseUser: any = getCookie("firebaseUser");
  const app2 =
    firebase.apps.find((app) => app.name === "SecondProject") ||
    firebase.initializeApp(firebaseConfigLogs, "SecondProject");
  console.log("Sdf34fdsdfgsdgfdg", firebaseUser)
  // Get a reference to the database
  const database = app2.database().ref();
  const updatedData = data;
  const res = await fetchIpAddress();
  updatedData.date = moment().format("YYYY-MM-DD HH:mm:ss");
  if (firebaseUser && firebaseUser.name) {
    updatedData.metadata["userName"] = JSON.parse(firebaseUser)?.name;
  }
  updatedData.metadata["browser"] = browserName;
  updatedData.metadata["ip"] = res.data.ip;
  // Specify the desired path
  const logsRef = database
    .child("logs")
    .child(moment().format("YYYYMMDD"))
    .child(app)
    .child(locationId);

  // Push the data to a new child node in the specified path
  logsRef.push(data, (error) => {
    if (error) {
      console.log("fdgdfgdfgdfg Error saving data:", error);
    } else {
      console.log("fdgdfgdfgdfg Data saved successfully!");
    }
  });
};
