import React from "react";
import { Route, Switch } from "react-router-dom";
import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import CustomerListPage from "../pages/CustomerListPage";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Location from "../../PrestoExpressLocationApp/pages/Location";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "CustomerList",
      title: "CustomerList",
      path: "/customerList",
      icon: <span className="material-symbols-outlined">
      group
      </span>,
      isAuthorized: getIsAuthorized(Authorities.CUSTOMER_LIST_READ),
    },
  ];
  return sidebarRoute;
};
export interface TasksAppRoutesProps {}

const TasksAppRoutes: React.FunctionComponent<TasksAppRoutesProps> = () => {
  return (
    <Switch>
      <Route
        path="/:locationId/presto-customer/customerList"
        component={CustomerListPage}
        exact
      />
      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default TasksAppRoutes;
