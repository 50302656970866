import React from "react";
import { Icon } from "@iconify/react";
import { Route, Switch } from "react-router-dom";

import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import Dashboard from "../pages/Dashboard";
import Menu from "../pages/Menu";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import MenuInfo from "../pages/MenuInfo";
import Location from "../../PrestoExpressLocationApp/pages/Location";
import MappingStrategies from "../pages/MappingStrategies";
import PrinterMappingNew from "../pages/PrinterMappingNew";
import ProductConfiguration from "../pages/ProductConfiguration";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "DASHBOARD",
      title: "Dashboard",
      path: "",
      icon: <DashboardIcon />,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "MENU",
      title: "Menus",
      path: "/menu",
      icon: <MenuBookIcon />,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "MENU",
      title: "Printer Mapping",
      path: "/printerMapping",
      icon: <span className="material-symbols-outlined">print</span>,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "MENU",
      title: "Mapping Strategies",
      path: "/mappingStrategies",
      icon: <span className="material-symbols-outlined">alt_route</span>,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "internal",
      title: "Internal Configurations",
      path: "/presto-menus/internalConfiguration",
      icon: <Icon icon="ri:admin-line" height="24" width="24" />,
      isAuthorized: getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ),
      childRoutes: [
        {
          id: "internalConfiguration",
          title: "Product Configuration",
          path: "/internalConfiguration/productConfiguration",
          icon: <Icon icon="dashicons:admin-generic" height="24" width="24" />,
          isAuthorized: getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ),
        },
      ],
    },
  ];
  return sidebarRoute;
};

export interface MenuAppRoutesProps {}

const MenuAppRoutes: React.FunctionComponent<MenuAppRoutesProps> = () => {
  return (
    <Switch>
      <Route path="/:locationId/presto-menus/menu" component={Menu} exact />
      <Route
        path="/:locationId/presto-menus/:publishedMenuId/presto-menus-info"
        component={MenuInfo}
        exact
      />
      <Route path="/:locationId/presto-menus" component={Dashboard} exact />
      <Route
        path="/:locationId/presto-menus/printerMapping"
        component={PrinterMappingNew}
        exact
      />
      <Route
        path="/:locationId/presto-menus/mappingStrategies"
        component={MappingStrategies}
        exact
      />
      <Route
        path="/:locationId/presto-menus/internalConfiguration/productConfiguration"
        component={ProductConfiguration}
        exact
      />
      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default MenuAppRoutes;
