import React, { useContext } from "react";
import CustomerAppRoutes, {
  getSidebarRoutes,
} from "./routes/CustomerAppRoutes";
import { ThemeProvider } from "@material-ui/core/styles";
import { initTheme } from "./theme/primary";
import PrimaryLayout from "../../layouts/PrimaryLayout";
import { GlobalContext } from "../../root/App";
import { CssBaseline } from "@material-ui/core";

export interface PrestoExpressTasksAppProps {}

const PrestoExpressCustomerApp: React.FunctionComponent<PrestoExpressTasksAppProps> =
  () => {
    const [sidebarRoutes, setSidebarRoutes] = React.useState<any>([]);
    const { globalState } = useContext(GlobalContext);
    const theme = initTheme({ isDark: globalState.isDarkMode });
    const handleChangeSidebar = () => {
      const sidebar = getSidebarRoutes();
      setSidebarRoutes(sidebar);
    };

    React.useEffect(() => {
      handleChangeSidebar();
    }, []);

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PrimaryLayout
          sidebarRoutes={sidebarRoutes}
          handleChangeSidebar={handleChangeSidebar}
        >
          <CustomerAppRoutes />
        </PrimaryLayout>
      </ThemeProvider>
    );
  };

export default PrestoExpressCustomerApp;
