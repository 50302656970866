import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useContext } from "react";
import PrimaryLayout from "../../layouts/PrimaryLayout";
import { GlobalContext } from "../../root/App";
import MenuAppRoutes, { getSidebarRoutes } from "./routes/MenuAppRoutes";
import queryString from "query-string";
import { initTheme } from "./theme/primary";
import { useRouteMatch } from "react-router-dom";
import { getCookie } from "../../utils/cookies";
import { fetchAllLocations } from "../../services/locationApp/locationService";
import { getFilterListFromArrayObject } from "../../utils/commonArrayMap";
import { handleChangeRestaurant } from "../../utils";
import { MENU_QUERY_PARAMS } from "../../utils/consts";

export interface PrestoExpressMenuAppProps {}

const PrestoExpressMenuApp: React.FunctionComponent<
  PrestoExpressMenuAppProps
> = () => {
  const [sidebarRoutes, setSidebarRoutes] = React.useState<any>([]);
  const { globalState } = useContext(GlobalContext);
  const theme = initTheme({ isDark: globalState.isDarkMode });
  const match: any = useRouteMatch();

  /**
   * Asynchronous function to fetch location information based on the locationId from the server.
   * It utilizes cookies, API calls, and utility functions to update relevant parts of the application state.
   */
  const getLocationInfo = async () => {
    try {
      // Retrieve the idToken from cookies
      const idToken: any = getCookie("idToken");

      // Fetch all locations using the idToken
      const res = await fetchAllLocations(idToken);

      // Filter the location data based on the provided locationId
      const location = getFilterListFromArrayObject(
        res.data.data,
        match.params.locationId,
      );

      // Update the restaurant information based on the fetched location
      handleChangeRestaurant(location[0]);

      // Update the sidebar based on the new location information
      handleChangeSidebar();
    } catch (error) {}
  };

  /**
   * Function to handle changes in the sidebar. It fetches the sidebar routes
   * using the utility function getSidebarRoutes and updates the state with the new routes.
   */
  const handleChangeSidebar = () => {
    // Fetch the sidebar routes using the utility function
    const sidebar = getSidebarRoutes();

    // Update the state with the new sidebar routes
    setSidebarRoutes(sidebar);
  };

  /**
   * useEffect hook to fetch location information when the component mounts.
   * It calls the getLocationInfo function, which retrieves location data
   * based on the locationId parameter from the URL, and updates the restaurant
   * and sidebar accordingly.
   */
  React.useEffect(() => {
    // Call the function to fetch location information
    getLocationInfo();
  }, []);

  /**
   * Extracts the 'app' parameter from the query string in the window's location.
   * Uses the queryString library to parse the query string and retrieve the 'app' value.
   *
   * @constant {string} selectedApp - The value of the 'app' parameter from the query string.
   */
  const selectedApp = queryString.parse(window.location.search).app;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PrimaryLayout
        sidebarRoutes={sidebarRoutes}
        handleChangeSidebar={handleChangeSidebar}
      >
        {(sidebarRoutes.length > 0 || selectedApp === MENU_QUERY_PARAMS) && (
          <MenuAppRoutes />
        )}
      </PrimaryLayout>
    </ThemeProvider>
  );
};

export default PrestoExpressMenuApp;
