import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
  HeaderCell,
} from "@table-library/react-table-library/table";
import { useLocation } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import { useSort } from "@table-library/react-table-library/sort";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { sortDates, sortNumbers } from "../../../../utils/commonArrayMap";
import { handleDownloadPdf } from "./convertPdfExcel/convertDataToPdf";
import { handleDownloadCsv } from "./convertPdfExcel/convertDataToExcel";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";

export interface RevenueCenterReportInfoNodeProps {
  hardwareInformationNodeList: any;
  filterDetails: any;
  locationSelectedList: any;
  handleOnClickText: any;
  revenueDetailsObj: any;
}

const useStyles = (bodyLength: number) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        fontWeight: "bold",
        display: "flex",
        justifyContent: "start",
        paddingLeft: "12px",
        fontSize: "12px",
      },
      textStyleHeader: {
        display: "flex",
        justifyContent: "start",
        fontWeight: "bold",
        fontSize: "12px",
      },
      textStyle: {
        display: "flex",
        justifyContent: "start",
        fontSize: "12px",
      },
      textStyleEnd: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
        paddingRight: "12px",
      },
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.table_header_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      finalTotalRow: {
        cursor: "pointer",
        height: "40px",
        [`&:nth-of-type(${bodyLength})`]: {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
        },
      },
      totalRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "start",
        },
        "&:nth-of-type(n)": {
          color: theme.palette.custom.blue.contrastText,
          backgroundColor: theme.palette.custom.blue.main,
          "&:hover": {
            backgroundColor: theme.palette.custom.blue.light,
          },
        },
      },
      grandTotalRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "start",
        },
        "&:nth-of-type(n)": {
          color: theme.palette.custom.green.contrastText,
          backgroundColor: theme.palette.custom.green.main,
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
      },
      headerRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "start",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
        },
      },
      baseCellRow: {
        placeItems: "start",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: `${25}%`,
          width: `${25}%`,
        },
        [theme.breakpoints.down("xl")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: `${100 / 12}%`,
            width: `${100 / 12}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+11)`]: {
            minWidth: `${100 / 12}%`,
            width: `${100 / 12}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
            },
          },
        },
        [theme.breakpoints.down("lg")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: `${80 / 11}%`,
            width: `${80 / 11}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+11)`]: {
            minWidth: `${80 / 11}%`,
            width: `${80 / 11}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: `${20}%`,
            width: `${20}%`,
          },
        },

        [theme.breakpoints.down("md")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "13%",
            width: "13%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+11)`]: {
            minWidth: "13%",
            width: "13%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "16%",
            width: "16%",
          },
        },
        [theme.breakpoints.down("sm")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "16%",
            width: "16%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+11)`]: {
            minWidth: "16%",
            width: "16%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "20%",
            width: "20%",
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "24%",
            width: "24%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+11)`]: {
            minWidth: "24%",
            width: "24%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "26%",
            width: "26%",
          },
        },
      },
    }),
  );

/**
 * RevenueCenterReportInfoNode Component
 *
 * This component renders a revenue center report table along with export functionality for PDF and CSV formats.
 * It receives data about hardware information, filter details, selected locations, and revenue details as props.
 * The component dynamically generates table headers and rows based on the received data.
 * Users can export the table data as PDF or CSV using the export button.
 */
const RevenueCenterReportInfoNode: React.FunctionComponent<
  RevenueCenterReportInfoNodeProps
> = ({
  hardwareInformationNodeList,
  filterDetails,
  locationSelectedList,
  handleOnClickText,
  revenueDetailsObj,
}) => {
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [headersList, setHeadersList] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [bodyLength, setBodyLength] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const maxWidth = useMediaQuery("(max-width: 1300px)");
  const maxWidthCustom = useMediaQuery("(max-width: 2000px)");
  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const theme: CustomTheme = useTheme();

  /**
   * useEffect Hook
   *
   * This useEffect hook is responsible for resetting the nodes state
   * whenever the value of the "startDate" parameter in the URL query string changes.
   */
  useEffect(() => {
    // Reset the nodes state to an empty array when the start date parameter changes
    setNodes({ nodes: [] });
  }, [params.get("startDate")]);

  /**
   * handleTerminal Function
   *
   * This function takes an object representing terminal information and returns a human-readable
   * terminal type based on its subtype. If the object is null or undefined, it returns the terminal id.
   *
   * @param {object} idObj - An object representing terminal information.
   * @returns {string} - A human-readable terminal type or the terminal id.
   */
  const handleTerminal = (idObj: any) => {
    // Check if idObj exists
    if (idObj) {
      // Switch statement to determine the terminal type based on its subtype
      switch (idObj.sub_type) {
        case "-99":
          return "POS"; // Return "POS" for subtype -99
        case "pay-kiosk":
          return "Pay Kiosk"; // Return "Pay Kiosk" for subtype "pay-kiosk"
        case "order-kiosk":
          return "Order Kiosk"; // Return "Order Kiosk" for subtype "order-kiosk"
        case "wix":
          return "Online POS"; // Return "Online POS" for subtype "wix"
        default:
          return idObj.sub_type; // Return the subtype if it doesn't match any case
      }
    } else {
      // Return the terminal id if idObj is null or undefined
      return idObj.id;
    }
  };

  /**
   * handleTid Function
   *
   * This function takes an object representing terminal information and returns a formatted terminal ID
   * by appending a prefix "T-" to the terminal's prefix value. If the object is null or undefined,
   * it returns the terminal id.
   */
  const handleTid = (idObj: any) => {
    // Check if idObj exists
    if (idObj) {
      // Return a formatted terminal ID with prefix "T-"
      return `T-${idObj.prefix}`;
    } else {
      // Return the terminal id if idObj is null or undefined
      return idObj.id;
    }
  };

  /**
   * handleSortedData Function
   *
   * This function takes an array of data objects and sorts them based on the 'section' property.
   *
   * @param {Array} data - An array of data objects to be sorted.
   * @returns {Array} - The sorted array of data objects.
   */
  const handleSortedData = (data: any) => {
    // Sort the data array based on the 'section' property
    const sortedData = data.sort((a: any, b: any) => {
      if (a.section < b.section) {
        return -1; // Return -1 if 'a.section' is less than 'b.section'
      } else if (a.section > b.section) {
        return 1; // Return 1 if 'a.section' is greater than 'b.section'
      } else {
        return 0; // Return 0 if 'a.section' is equal to 'b.section'
      }
    });

    // Return the sorted array of data objects
    return sortedData;
  };

  /**
   * Formats the table data based on hardware information and revenue details.
   * This function iterates through the hardware information node list and revenue details object
   * to generate formatted data for display in a table. It checks if the terminal type is not a 'printer-server'
   * and the type is 'pos' to include it in the formatted data. If the corresponding data is not found in
   * 'hardwareInformationNodeList', it categorizes it as 'Uncategorized Terminals'.
   *
   * @param {Object} hardwareInformationNodeList - The list of hardware information nodes.
   * @param {Object} revenueDetailsObj - The object containing revenue details.
   * @returns {Array} - Formatted table data.
   */
  const handleTableData = (
    hardwareInformationNodeList: any,
    revenueDetailsObj: any,
  ) => {
    const formattedData: any = [];
    // Iterate through each entry in 'hardwareInformationNodeList'.
    Object.entries(hardwareInformationNodeList).forEach(([key, value]: any) => {
      // Check if the terminal type is not 'printer-server' and the type is 'pos'.
      if (handleTerminal(value) !== "printer-server" && value.type === "pos") {
        // Push formatted data for POS terminals into the 'formattedData ' array.
        formattedData.push({
          section: value.section,
          prefix: `${handleTid(value)} (${handleTerminal(value)})`,
          average: revenueDetailsObj[key]
            ? parseFloat(revenueDetailsObj[key].average).toFixed(2)
            : "0.00",
          card: revenueDetailsObj[key]
            ? parseFloat(revenueDetailsObj[key].card).toFixed(2)
            : "0.00",
          cash: revenueDetailsObj[key]
            ? parseFloat(revenueDetailsObj[key].cash).toFixed(2)
            : "0.00",
          discount: revenueDetailsObj[key]
            ? parseFloat(revenueDetailsObj[key].discount).toFixed(2)
            : "0.00",
          min: revenueDetailsObj[key]
            ? parseFloat(revenueDetailsObj[key].min).toFixed(2)
            : "0.00",
          max: revenueDetailsObj[key]
            ? parseFloat(revenueDetailsObj[key].max).toFixed(2)
            : "0.00",
          orders: revenueDetailsObj[key] ? revenueDetailsObj[key].orders : 0,
          otherPayment: revenueDetailsObj[key]
            ? parseFloat(revenueDetailsObj[key].otherPayment).toFixed(2)
            : "0.00",
          subTotal: revenueDetailsObj[key]
            ? parseFloat(revenueDetailsObj[key].subTotal).toFixed(2)
            : "0.00",
          total: revenueDetailsObj[key]
            ? parseFloat(revenueDetailsObj[key].total).toFixed(2)
            : "0.00",
        });
      }
    });

    // Iterate through each entry in 'revenueDetailsObj'.
    Object.entries(revenueDetailsObj).forEach(([key, value]: any) => {
      // Find the data corresponding to the 'key' in 'hardwareInformationNodeList'.
      const data = Object.values(hardwareInformationNodeList).filter(
        (data: any) => data.dev_id === key,
      );

      const idObj: any = data[0];
      if (!idObj) {
        // If the corresponding data is not found in 'hardwareInformationNodeList',
        // push formatted data for uncategorized terminals into the 'formattedData' array.
        formattedData.push({
          section: "Uncategorised Terminals",
          prefix: key,
          average: parseFloat(value.average).toFixed(2),
          card: parseFloat(value.card).toFixed(2),
          cash: parseFloat(value.cash).toFixed(2),
          discount: parseFloat(value.discount).toFixed(2),
          min: parseFloat(value.min).toFixed(2),
          max: parseFloat(value.max).toFixed(2),
          orders: value.orders,
          otherPayment: parseFloat(value.otherPayment).toFixed(2),
          subTotal: parseFloat(value.subTotal).toFixed(2),
          total: parseFloat(value.total).toFixed(2),
        });
      }
    });

    return formattedData;
  };

  /**
   * Calculates total and grand total entries for the sorted body of data.
   *
   * @param {Array} sortedBody - The sorted body of data.
   * @returns {Array} - Array containing total and grand total entries.
   */
  const handleTotal = (sortedBody: any) => {
    const output: any = [];
    // Initialize total entry with default values
    let totalEntry: any = {
      section: "Total",
      prefix: "",
      average: 0,
      card: 0,
      cash: 0,
      discount: 0,
      orders: 0,
      otherPayment: 0,
      subTotal: 0,
      total: 0,
    };
    let count = 0;
    let min = 10000000;
    let max = 0;

    // Process each entry in the sorted body
    sortedBody.forEach((entry: any, index: any, prev: any) => {
      output.push(entry); // Push original entry
      // Calculate averages and totals
      count = count + 1;
      Object.keys(totalEntry).forEach((key: any) => {
        if (key !== "section" && key !== "prefix") {
          totalEntry[key] += parseFloat(entry[key]);
        }
      });

      // Update minimum and maximum values
      if (parseFloat(min.toString()) > parseFloat(entry.min)) {
        min = entry.min;
      }

      if (parseFloat(max.toString()) < parseFloat(entry.max)) {
        max = entry.max;
      }

      // Check if it's the last entry of the section
      if (
        entry.section !== prev[index + 1]?.section ||
        index + 1 === sortedBody.length
      ) {
        // Calculate average and update total entry
        totalEntry.average =
          parseFloat(totalEntry.orders) === 0
            ? "0.00"
            : parseFloat(
                (
                  parseFloat(totalEntry.total) / parseFloat(totalEntry.orders)
                ).toString(),
              ).toFixed(2);

        totalEntry.min = parseFloat(min.toString()).toFixed(2);
        totalEntry.max = parseFloat(max.toString()).toFixed(2);
        totalEntry.card = parseFloat(totalEntry.card.toString()).toFixed(2);
        totalEntry.cash = parseFloat(totalEntry.cash.toString()).toFixed(2);
        totalEntry.discount = parseFloat(
          totalEntry.discount.toString(),
        ).toFixed(2);
        totalEntry.otherPayment = parseFloat(
          totalEntry.otherPayment.toString(),
        ).toFixed(2);
        totalEntry.subTotal = parseFloat(
          totalEntry.subTotal.toString(),
        ).toFixed(2);
        totalEntry.total = parseFloat(totalEntry.total.toString()).toFixed(2);

        // Add section total to the output
        totalEntry["section"] = `${entry.section} Total:`;

        output.push(totalEntry); // Push total entry for the section

        // Reset counters and total entry
        count = 0;
        totalEntry = {
          section: "Total",
          prefix: "",
          average: 0,
          card: 0,
          cash: 0,
          discount: 0,
          min: 0,
          max: 0,
          orders: 0,
          otherPayment: 0,
          subTotal: 0,
          total: 0,
        };
        min = 10000000;
        max = 0;
      }
    });

    // Calculate Grand Total
    let grandTotalEntry: any = {
      section: "Grand Total",
      prefix: "",
      average: 0,
      card: 0,
      cash: 0,
      discount: 0,
      min: 0,
      max: 0,
      orders: 0,
      otherPayment: 0,
      subTotal: 0,
      total: 0,
    };

    let minValue = 100000;
    let maxValue = 0;

    // Iterate over the output to calculate grand total
    output.forEach((entry: any) => {
      if (!entry.section.includes("Total")) {
        // Update min and max values
        if (minValue > parseFloat(entry.min)) {
          minValue = entry.min;
        }

        if (maxValue < parseFloat(entry.max)) {
          maxValue = entry.max;
        }

        // Accumulate values for grand total
        Object.keys(grandTotalEntry).forEach((key: any) => {
          if (key !== "section" && key !== "prefix") {
            grandTotalEntry[key] += parseFloat(entry[key]);
          }
        });
      }
    });

    // Format grand total values
    grandTotalEntry.card = parseFloat(grandTotalEntry.card).toFixed(2);
    grandTotalEntry.cash = parseFloat(grandTotalEntry.cash).toFixed(2);
    grandTotalEntry.discount = parseFloat(grandTotalEntry.discount).toFixed(2);
    grandTotalEntry.min = parseFloat(minValue.toString()).toFixed(2);
    grandTotalEntry.max = parseFloat(maxValue.toString()).toFixed(2);
    grandTotalEntry.orders = grandTotalEntry.orders;
    grandTotalEntry.otherPayment = parseFloat(
      grandTotalEntry.otherPayment,
    ).toFixed(2);
    grandTotalEntry.subTotal = parseFloat(grandTotalEntry.subTotal).toFixed(2);
    grandTotalEntry.total = parseFloat(grandTotalEntry.total).toFixed(2);

    // Calculate grand total average
    grandTotalEntry.average =
      parseFloat(grandTotalEntry.orders) === 0
        ? "0.00"
        : parseFloat(
            (grandTotalEntry.total / grandTotalEntry.orders).toString(),
          ).toFixed(2);

    output.push(grandTotalEntry); // Push grand total entry

    return output;
  };

  /**
   * useEffect hook to handle data processing and set state variables for rendering.
   * It formats the data, calculates totals, sets headers for PDF and Excel exports,
   * and updates state variables for rendering.
   *
   * @param {Array} hardwareInformationNodeList - List of hardware information nodes.
   * @param {Object} revenueDetailsObj - Object containing revenue details.
   */
  useEffect(() => {
    // Initialize an empty array to hold the formatted data.
    const formattedData = handleTableData(
      hardwareInformationNodeList,
      revenueDetailsObj,
    );

    // Sort the formatted data.
    const sortedBody = handleSortedData(formattedData);

    // Calculate totals and grand totals.
    const output = handleTotal(sortedBody);

    // setFooter([formattedFooter]);

    setBodyLength(output.length + 1);

    // // Set pdf headers
    setPdfHeaders([
      { title: "Section", field: "section" },
      { title: "Terminal", field: "prefix" },
      { title: "Orders", field: "orders" },
      { title: "Sub Total", field: "subTotal" },
      { title: "Discount", field: "discount" },
      { title: "Total", field: "total" },
      { title: "Cash Pay", field: "cash" },
      { title: "Card Pay", field: "card" },
      { title: "Other Pay", field: "otherPayment" },
      { title: "Average", field: "average" },
      { title: "Min", field: "min" },
      { title: "Max", field: "max" },
    ]);

    // Set excel headers
    setExcelHeaders([
      { header: "Section", key: "section", width: 52, height: 68 },
      { header: "Terminal", key: "prefix", width: 32, height: 68 },
      { header: "Orders", key: "orders", width: 32, height: 68 },
      { header: "Sub Total", key: "subTotal", width: 32, height: 68 },
      { header: "Discount", key: "discount", width: 52, height: 68 },
      { header: "Total", key: "total", width: 32, height: 68 },
      { header: "Cash Pay", key: "cash", width: 32, height: 68 },
      { header: "Card Pay", key: "card", width: 32, height: 68 },
      { header: "Other Pay", key: "otherPayment", width: 52, height: 68 },
      { header: "Average", key: "average", width: 52, height: 68 },
      { header: "Min", key: "min", width: 32, height: 68 },
      { header: "Max", key: "max", width: 32, height: 68 },
    ]);

    // Set table headers
    setHeadersList([
      "Section",
      "Terminal",
      "Orders",
      "Sub Total",
      "Discount",
      "Total",
      "Cash Pay",
      "Card Pay",
      "Other Pay",
      "Average",
      "Min",
      "Max",
    ]);

    // Set data for PDF and Excel exports.
    setNodesPdfExcel(output);

    // Set state variable for rendering the table.
    setNodes({ nodes: output });
  }, [hardwareInformationNodeList, revenueDetailsObj]);

  /**
   * Custom hook for handling sorting functionality.
   * It sorts the provided nodes based on different criteria.
   */
  const sort = useSort(
    // Input nodes to be sorted
    nodes,
    // Additional options (empty in this case)
    {},
    {
      // Define sorting functions for different fields
      sortFns: {
        // Sort by Date
        Date: (array) => sortDates(array, "date"),
        // Sort by Orders
        Orders: (array) => sortNumbers(array, "orders"),
        // Sort by SubTotal
        SubTotal: (array) => sortNumbers(array, "subTotal"),
        // Sort by Discount
        Discount: (array) => sortNumbers(array, "discount"),
        // Sort by Total
        Total: (array) => sortNumbers(array, "total"),
        // Sort by Cash Pay
        "Cash Pay": (array) => sortNumbers(array, "cash"),
        // Sort by Card Pay
        "Card Pay": (array) => sortNumbers(array, "card"),
        // Sort by Other Pay
        "Other Pay": (array) => sortNumbers(array, "otherPayment"),
        // Sort by Average
        Average: (array) => sortNumbers(array, "average"),
        // Sort by Min
        Min: (array) => sortNumbers(array, "min"),
        // Sort by Max
        Max: (array) => sortNumbers(array, "max"),
      },
      // Define custom sort icons
      sortIcon: {
        margin: "0px",
        // Default sort icon
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        // Icon for ascending sort
        iconUp: <ArrowDropDownIcon />,
        // Icon for descending sort
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  /**
   * Function to handle click event on a button element.
   * It sets the anchor element for the popover based on the event's current target.
   * @param {React.MouseEvent<HTMLButtonElement>} event - The click event object.
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Set the anchor element for the popover to the current target of the event
    setAnchorEl(event.currentTarget);
  };

  /**
   * Function to handle closing of a popover.
   * It sets the anchor element to null, effectively closing the popover.
   */
  const handleClose = () => {
    // Set the anchor element to null to close the popover
    setAnchorEl(null);
  };

  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const classes: any = useStyles(bodyLength)();

  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? { display: "block" }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
            {!_.isEmpty(nodes.nodes) && (
              <div
                style={
                  maxWidthLocationChips
                    ? {
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "8px",
                      }
                    : { marginLeft: "16px" }
                }
              >
                <MenuButtonCommon
                  id="basic-button"
                  variant="contained"
                  style={{
                    fontSize: 11,
                    height: "38px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  color="blue"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <GetAppIcon />
                  <Typography
                    variant="body1"
                    style={{ paddingLeft: "16px", color: "white" }}
                  >
                    Export
                  </Typography>
                </MenuButtonCommon>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    style: {
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    },
                  }}
                  style={{ top: "56px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownloadPdf(
                        "p",
                        pdfHeaders,
                        nodesPdfExcel,
                        filterDetails,
                      );
                      handleClose();
                    }}
                  >
                    Export as PDF
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDownloadCsv(
                        excelHeaders,
                        nodesPdfExcel,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as CSV
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <CardCommon>
        <Table
          data={nodes}
          sort={sort}
          layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
        >
          {(tableList) => (
            <>
              <Header style={{ zIndex: 0 }}>
                <HeaderRow className={classes.headerStyle}>
                  {headersList.map((data: any, index: any) => (
                    <HeaderCell
                      sortKey={data}
                      pinLeft={index === 0}
                      className={classes.baseCellRow}
                      style={{ fontWeight: "bold" }}
                    >
                      <Typography
                        variant="caption"
                        className={
                          index === 0
                            ? classes.startTextStyle
                            : index === 11
                            ? classes.textStyleEnd
                            : classes.textStyleHeader
                        }
                      >
                        {data}
                      </Typography>
                    </HeaderCell>
                  ))}
                </HeaderRow>
              </Header>
              <Body className={classes.bodyStyle}>
                {tableList.map((item, bodyIndex, prevItem: any) => (
                  <>
                    <Row
                      className={
                        item.section.includes("Total") &&
                        item.section !== "Grand Total"
                          ? classes.totalRow
                          : item.section === "Grand Total"
                          ? classes.grandTotalRow
                          : classes.headerRow
                      }
                      key={item.gross20}
                      item={item}
                    >
                      <Cell pinLeft className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          align="left"
                          style={
                            item.section.includes("Total")
                              ? { fontWeight: "bold", color: "white" }
                              : { fontWeight: "bold" }
                          }
                          className={classes.startTextStyle}
                        >
                          {item["section"]}
                        </Typography>
                      </Cell>
                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          style={
                            item.section.includes("Total")
                              ? { color: "white", fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                          align="left"
                          className={classes.textStyle}
                        >
                          {item["prefix"]}
                        </Typography>
                      </Cell>
                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          style={
                            item.section.includes("Total")
                              ? { color: "white", fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                          className={classes.textStyle}
                        >
                          {item["orders"]}
                        </Typography>
                      </Cell>

                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          style={
                            item.section.includes("Total")
                              ? { color: "white", fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                          className={classes.textStyle}
                        >
                          {item["subTotal"]}
                        </Typography>
                      </Cell>
                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          style={
                            item.section.includes("Total")
                              ? { color: "white", fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                          className={classes.textStyle}
                        >
                          {item["discount"]}
                        </Typography>
                      </Cell>
                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          style={
                            item.section.includes("Total")
                              ? { color: "white", fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                          className={classes.textStyle}
                        >
                          {item["total"]}
                        </Typography>
                      </Cell>
                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          style={
                            item.section.includes("Total")
                              ? { color: "white", fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                          className={classes.textStyle}
                        >
                          {item["cash"]}
                        </Typography>
                      </Cell>
                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          style={
                            item.section.includes("Total")
                              ? { color: "white", fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                          className={classes.textStyle}
                        >
                          {item["card"]}
                        </Typography>
                      </Cell>
                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          style={
                            item.section.includes("Total")
                              ? { color: "white", fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                          className={classes.textStyle}
                        >
                          {item["otherPayment"]}
                        </Typography>
                      </Cell>
                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          style={
                            item.section.includes("Total")
                              ? { color: "white", fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                          className={classes.textStyle}
                        >
                          {item["average"]}
                        </Typography>
                      </Cell>

                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          style={
                            item.section.includes("Total")
                              ? { color: "white", fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                          className={classes.textStyle}
                        >
                          {item["min"]}
                        </Typography>
                      </Cell>
                      <Cell className={classes.baseCellRow}>
                        <Typography
                          variant="caption"
                          style={
                            item.section.includes("Total")
                              ? { color: "white", fontWeight: "bold" }
                              : { fontWeight: "normal" }
                          }
                          className={classes.textStyleEnd}
                        >
                          {item["max"]}
                        </Typography>
                      </Cell>
                    </Row>
                  </>
                ))}
              </Body>
            </>
          )}
        </Table>
      </CardCommon>
    </>
  );
};

export default RevenueCenterReportInfoNode;
